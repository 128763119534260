export type AlertProps = {
  children: React.ReactNode;
};

// TODO add `type` prop for different types of alerts
export function Alert({ children }: AlertProps) {
  return (
    <div role="alert" className="mb-10 rounded-md bg-red-50 p-4">
      <h3 className="text-sm font-medium text-red-800">{children}</h3>
    </div>
  );
}
