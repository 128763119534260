import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { z } from "zod";

const clientUserSessionParser = z.object({
  apiToken: z.string(),
  expiresAt: z.string(),
  userId: z.string(),
  userDisplayName: z.string(),
  orgId: z.string().optional(),
});

export type ClientUserSession = z.infer<typeof clientUserSessionParser>;

export const sessionStaleTime = 5 * 60 * 1000; // 5 min

export const sessionKey = ["session"];

export const sessionQuery = async () => {
  const response = await fetch("/auth/session");

  if (!response.ok) {
    console.warn("Unable to retrieve session, redirecting to /");
    window.location.href = "/";
  }

  const json = await response.json();
  return clientUserSessionParser.parse(json);
};

export function useSessionQuery() {
  return useQuery({
    queryKey: sessionKey,
    queryFn: sessionQuery,
    staleTime: sessionStaleTime,
    placeholderData: keepPreviousData,
  });
}

export async function readSessionFlash(flashKey: string): Promise<boolean> {
  const params = new URLSearchParams({ read: flashKey });
  const response = await fetch(`/auth/session?${params.toString()}`, {
    method: "PATCH",
  });
  return response.ok;
}
