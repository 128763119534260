"use client";

import { useEffect } from "react";
import { readSessionFlash } from "~/auth.client";
import { Alert } from "~/components/alert";

type AuthErrorProps = {
  flashKey: string;
  flashMessage: string;
};

/**
 * One-off client component for `/auth/login` page.tsx that displays the error
 * on the login screen from a flash message from the session. This calls the
 * server to mark the provided flash key as read and saved to the session.
 */
export function AuthError({ flashKey, flashMessage }: AuthErrorProps) {
  useEffect(() => {
    readSessionFlash(flashKey);
  }, [flashKey]);

  return <Alert>{flashMessage}</Alert>;
}
